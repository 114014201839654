import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
//import "sweetalert2/dist/sweetalert2.min.css";

import "@/utils/filters";
const options = { icon: "success", position: "center", showConfirmButton: false, timer: 1500 };
Vue.use(VueSweetalert2, options);
import { validationMixin } from "@/utils/validationMixin";
Vue.mixin(validationMixin);
import VueMoment from "vue-moment";
const moment = require("moment");
require("moment/locale/en-gb");
require("moment/locale/fr");
Vue.use(VueMoment, {
  moment
});

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );

import VueHtmlToPaper from 'vue-html-to-paper';
const printOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css"
  ]
}

Vue.use(VueHtmlToPaper, printOptions);
Vue.config.productionTip = false;
export const bus = new Vue();

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
