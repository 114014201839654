import Vue from "vue";
import Vuex from "vuex";
import jwt_decode from "jwt-decode";
import router from "../router/router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
    role: null
  },
  mutations: {
    authUser(state, userData) {
      state.token = userData.access_token;
      state.user = userData.user;
      state.role = userData.role;
    },
    updateUserImage(state, imageData) {
      state.user.image = imageData;
    },
    clearAuthData(state) {
      state.user = null;
      state.token = null;
      state.role = null;
    }
  },
  actions: {
    tryAutoLogin({ commit }) {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const decoded = jwt_decode(token);
      const role = decoded.scopes[0];
      const user = JSON.parse(localStorage.getItem("user"));
      commit("authUser", {
        access_token: token,
        user: user,
        role: role
      });
    },
    logout({ commit }) {
      commit("clearAuthData");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("refresh_token");
      router.replace("/login");
    }
  },
  getters: {
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.token !== null;
    },
    getRole(state) {
      return state.role;
    },
    isAdmin(state) {
      return state.role === "admin";
    },
    isAgent(state) {
      return state.role === "agent";
    }
  }
});
