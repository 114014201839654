import  Vue  from  "vue";
const moment = require("moment");
Vue.filter("moment_time", function(date) {
  // if (!date) return "";
  // if (date >= 10) {
  //   return date.replace(".", ":");
  // }
  // return "0" + date.replace(".", "H");

  return moment
    .utc(moment.duration(date, "hours").asMilliseconds())
    .format("HH:mm");
});
Vue.filter("moment_hours", function(date) {
  if (!date) return "";
  return date.slice(0, date.indexOf("."));
});
Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});