export const validationMixin = {
  data() {
    return {
      passwordRules: [
        v => !!v || "Le mot de passe est obligatoire",
        v =>
          (v && v.length >= 6) ||
          "Le mot de passe doit contenir plus de 6 caractères"
      ],
      emailRules: [
        v => !!v || "L'adresse email est obligatoire",
        v => /.+@.+\..+/.test(v) || "L'adresse email est invalide"
      ],
      requiredRules: [v => (Array.isArray(v) ? v.length > 0 : !!v) || "Ce champ est obligatoire"]
    };
  }
};
