<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {
    this.$store.dispatch("tryAutoLogin");
  }
};
</script>
<style>
.headline {
  font-family: "Lato", serif;
  color: #003d42;
  font-size: 34px;
  display: block;
}
.total-hours {
  color: #003d42;
  font-family: "Lato", serif;
  font-size: 16px;
  font-weight: bold;
}
.total {
  font-weight: 100;
  font-style: normal;
}

.active-avatar {
  background: #86bc26;
  border-radius: 9px;
  position: absolute;
  width: 9px;
  height: 9px;
  right: 0;
  bottom: 2px;
}
.inactive-avatar {
  background: #e73c41;
  border-radius: 9px;
  position: absolute;
  width: 9px;
  height: 9px;
  right: 0;
  bottom: 2px;
}
.agent-avatar {
  overflow: visible;
  box-shadow: #000000 0 1px 5px;
  margin-left: 10px;
  cursor: pointer;
}
#toolbar-title {
  font-family: "Lato", serif;
  font-weight: normal;
  font-size: 34px;
  text-align: left;
  color: #003d42;
}
.inactive-status{
  background:#E73C41;
  color: white !important;
  border-radius: 50%
}
.active-status{
  background: #86BC26;
  color: white !important;
  border-radius: 50%
}
.btn-actions{
  justify-content: center;
}
.pointer{
  cursor: pointer;
}
.custom-switcher .v-input--selection-controls__input div {
  color: #86bc26 !important;
}
.custom-switcher .v-input--selection-controls__input .v-input--switch__thumb {
  color: greenyellow !important;
}
.custom-ck-editor{
  border: 1px solid var(--ck-color-toolbar-border) !important;
  min-height: 250px !important;
}
.custom-ck-editor > #cke_editor1{
  border-radius: 20px;
  overflow: hidden;
}
.custom-ck-editor #cke_1_top{
  background: white;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day{
  border: #e0e0e0 1px solid !important;
  margin: 10px !important;
  height: 100px !important;
}
.theme--light.v-calendar-weekly{
  border: none !important;
}
.v-calendar-weekly__week{
  min-height: 120px !important;

}
</style>
