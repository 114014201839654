import Vue from "vue";
import Router from "vue-router";
import store from "../store";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "", redirect: "dashboard" },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/authentication/login"),
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/authentication/forgot-password"),
    },
    {
      path: "/reset-password/:token",
      name: "reset-password",
      component: () => import("@/views/authentication/reset-password"),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/dashboard/index"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "statistics",
          component: () => import("@/views/admin/statistics/statistics"),
          meta: { requiresAuth: true, authorize: "admin" },
        },
        {
          path: "profile/password",
          component: () =>
            import("@/views/profile/edit-password/edit-password-card"),
          meta: { requiresAuth: true, group: "profile" },
        },
        {
          path: "profile/photo",
          component: () => import("@/views/profile/edit-photo/edit-photo-card"),
          meta: {
            requiresAuth: true,
            title: "Mon compte",
            group: "profile",
          },
        },
        {
          path: "timesheet",
          component: () => import("@/views/agent/timesheet/timesheet-list"),
          meta: { requiresAuth: true },
        },
        {
          path: "projects",
          component: () => import("@/views/agent/dossiers/dossiers"),
          meta: { requiresAuth: true },
        },
        {
          path: "conge",
          component: () => import("@/views/agent/conge/conge"),
          meta: { requiresAuth: true },
        },
        {
          path: "clients",
          component: () => import("@/views/admin/clients/clients"),
          meta: {
            requiresAuth: true,
            authorize: "admin",
            group: "dossier",
          },
        },
        {
          path: "timesheet/list",
          component: () => import("@/views/admin/timesheet/timesheets"),
          meta: {
            authorize: "admin",
            group: "timesheet",
          },
        },
        {
          path: "timesheetMensuel/list",
          component: () => import("@/views/admin/timesheet/timesheetMensuel"),
          meta: {
            authorize: "admin",
          },
        },
        {
          path: "rapport/list",
          component: () => import("@/views/admin/rapports/rapports"),
          meta: {
            authorize: "admin",
            group: "rapport",
          },
        },
        {
          path: "rapport/types",
          component: () => import("@/views/admin/rapports/types-rapports"),
          meta: {
            authorize: "admin",
            group: "rapport",
          },
        },
        {
          path: "timesheet/manquants",
          component: () => import("@/views/admin/timesheet/manquants"),
          meta: {
            authorize: "admin",
            group: "timesheet",
          },
        },
        {
          path: "timesheet/ignorer",
          component: () => import("@/views/admin/timesheet/ignorer"),
          meta: {
            authorize: "admin",
            group: "timesheet",
          },
        },
        {
          path: "timesheet-detail",
          component: () => import("@/views/agent/timesheet/timesheet-detail"),
          meta: { title: "TimeSheet", requiresAuth: true },
        },
        {
          path: "ressources",
          component: () => import("@/views/admin/ressources/ressources"),
          meta: {
            authorize: "admin",
          },
        },
        {
          path: "dossier",
          component: () => import("@/views/admin/dossiers/details_dossier"),
          meta: {
            authorize: "admin",
            group: "dossier",
          },
        },
        {
          path: "roles",
          component: () => import("@/views/admin/roles/roles"),
          meta: {
            authorize: "admin",
            group: "roles",
          },
        },
        {
          path: "operations",
          component: () => import("@/views/admin/operations/operations"),
          meta: {
            authorize: "admin",
            group: "roles",
          },
        },
        {
          path: "dossiers",
          component: () => import("@/views/admin/dossiers/dossiers"),
          meta: {
            authorize: "admin",
            group: "dossier",
          },
        },
        {
          path: "timesheet/prolongations",
          component: () => import("@/views/admin/prolongations/prolongations"),
          meta: {
            authorize: "admin",
            group: "timesheet",
          },
        },
        {
          path: "timesheet/uploads",
          component: () => import("@/views/admin/uploads/uploads"),
          meta: {
            authorize: "admin",
            group: "timesheet",
          },
        },
        {
          path: "rapport",
          component: () => import("@/views/agent/rapport/rapports"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "conge/follow",
          component: () => import("@/views/admin/conge/conge"),
          meta: {
            authorize: "admin",
          },
        },
        {
          path: "dossier/config",
          component: () => import("@/views/admin/dossiers/dossiers-config"),
          meta: {
            authorize: "admin",
          },
        },
      ],
    },
  ],
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some((m) => m.meta.requiresAuth) && !store.state.token) {
    next({
      name: "login",
    });
  } else if (
    to.matched.some((m) => m.meta.authorize) &&
    !to.meta.authorize.includes(store.state.role)
  ) {
    next({
      name: "dashboard",
    });
  } else {
    next();
  }
});

export default router;
